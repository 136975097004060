import { React, useState, useEffect } from 'react';
import { api_call } from '../../utils';
import { Row, Col, Button, Card, DatePicker, Form, } from 'antd';
import {Xtable, Xpagination} from '../../component/Xcomponent';
import moment from "moment";
import _ from "lodash";

const NgoGuide = (props) => {
    const [role, setRole] = useState(localStorage.userRole);
    const [ngoGuideDetails, setNgoGuideDetails] = useState([]);

    const fetchNgoGuideDetails =(search) => {
        api_call('ngo_guide_details',search)
        .then((res)=>{
            let tableData = [];
            if(res.data.status){
                res.data.data.map((item) => {
                    tableData.push({
                        name: item.name,
                        email: item.email,
                        mobile: item.mobile_number,
                    })
                })
            }
            setNgoGuideDetails(tableData)
        })
    }

    useEffect(()=>{
        if (role ==1 || role == 2) {
            fetchNgoGuideDetails({})
        }
    },[])

    const columns = [
        {
            title:'Name',
            dataIndex:'name',
            key:'name'
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email'
        },
        {
            title: 'Mobile Number',
            dataIndex: 'mobile',
            key: 'mobile'
        },
    ]

    return ( <>
       {(role == 1 || role == 2)  && <>
            <Row gutter={{offset:1, span:23}} style={{margin:"10px"}}>
              <Col span={24}>
                <Card
                    title="Ngo Guide"
                    style={{
                        width:"100%",
                        minHeight:"100px",
                    }}
                >
                <Xtable
                    columns={columns}
                    dataSource={ngoGuideDetails}
                    pagination={true}
                    scroll={{x: 768}}
                    style={{
                        minHeight:"200px",
                    }}
                />
                {/* <Xpagination
                    pageSizeOptions={["10", "25", "50", "100", "500"]}
                    showSizeChanger
                    total={totalRecords}
                    onChange={(page, pageSize) =>
                        setPagination({ page: page, records: pageSize })
                    }
                    current={pagination.page}
                    defaultPageSize={pagination.records}
                    defaultCurrent={1}
                /> */}
                </Card>
              </Col>
            </Row> 
            </>
        }
       </> 
    );
}

export default NgoGuide