import {React,useState} from 'react';
import { Menu, Layout, Row, Col, Typography, Space } from 'antd';
import { Link } from "react-router-dom";
import {
    ScheduleFilled,
    HomeFilled
  } from "@ant-design/icons";
import { getUserRole } from './../utils'
const { Sider } = Layout;
const {Title} = Typography;

const Sidebar = (props) =>{
    const [role, setRole] = useState(localStorage.userRole);
    
    const menuItems = [
        {
            path:"/",
            label:(
                <Link to={"/"}>
                    <span className='menuName'>DashBoard</span>
                </Link>
            ),
            key:"1",
            icon: <HomeFilled />,
        },
        {
            path:"/attendance",
            label:(
                <Link to={"/attendance"}>
                    <span className='menuName'>Attendance</span>
                </Link>
            ),
            key:"2",
            icon: <ScheduleFilled />,
        },
        {
            path:"/leave",
            label:(
                <Link to={"/leave"}>
                    <span className='menuName'>Leave</span>
                </Link>
            ),
            key:"3",
            icon: <span role="img" className="ant-menu-item-icon"> 
            <i className="fas fa-calendar-minus"></i>  
            </span> 
        },
        {
            path:"/holiday",
            label:(
                <Link to={"/holiday"}>
                    <span className='menuName'>Holidays</span>
                </Link>
            ),
            key:"4",
            icon:<span role="img" className="ant-menu-item-icon"> 
            <i className="fas fa-calendar-times"></i>  
            </span> 
        },
        {
            path:"/accounts",
            label:(
                <Link to={"/accounts"}>
                    <span className='menuName'>Accounts</span>
                </Link>
            ),
            key:"5",
            icon: <span role="img" className="ant-menu-item-icon"> 
            <i className="fas fa-file-invoice"></i>  
            </span> 
        },
        {
            path:"/ngos",
            label:(
                <Link to={"/ngos"}>
                    <span className='menuName'>Ngos</span>
                </Link>
            ),
            key:"6",
            icon: <span role="img" className="ant-menu-item-icon"> 
                    <i className="fas fa-sitemap"></i>
                </span>
        },
        {
            path:"/ngoadmin",
            label:(
                <Link to={"/ngoadmin"}>
                    <span className='menuName'>Ngo Head</span>
                </Link>
            ),
            key:"7",
            icon: <span role="img" className="ant-menu-item-icon"> 
                    <i className="fas fa-user-shield"></i>
                </span>
        },
        {
            path:"/ngoguide",
            label:(
                <Link to={"/ngoguide"}>
                    <span className='menuName'>Ngo Guide</span>
                </Link>
            ),
            key:"8",
            icon:<span role="img" className="ant-menu-item-icon"> 
            <i className="fas fa-chalkboard-teacher"></i>
        </span>
        },
        {
            path:"/fellow",
            label:(
                <Link to={"/fellow"}>
                    <span className='menuName'>Fellows</span>
                </Link>
            ),
            key:"9",
            icon:<span role="img" className="ant-menu-item-icon"> 
            <i className="fas fa-id-card-alt"></i>
        </span>
        },
    ];

    // Function to filter menu items based on user role
    const filterMenuItems = (role) => {
        switch (role) {
        case "1":
            // For role 1, show all menus
            return menuItems;
        case "2":
            // For role 2, hide "Ngo Admin and Ngos" menu and show all others
            return menuItems.filter((menu) => menu.key !== "6" && menu.key !== "7");
        case "3":
            // For role 3, hide "Accounts", "Ngo Admin", and "Ngo Guide" menus
            return menuItems.filter(
            (menu) => menu.key !== "5" && menu.key !== "6" && menu.key !== "7" && menu.key !== "8"
            );
        case "4":
            // For role 4, hide "Accounts", "Ngo Admin", "Ngo Guide", and "Fellows" menus
            return menuItems.filter(
            (menu) =>
                menu.key !== "5" && menu.key !== "6" && menu.key !== "7" && menu.key !== "8" && menu.key !== "9"
            );
        default:
            // Default to showing all menus for unknown roles
            return menuItems;
        }
    };

    // Get the filtered menu items based on the user's role
    const filteredMenuItems = filterMenuItems(role);

    const getKey = () => {
        let key  
        menuItems.find((menu)=>{
            if(menu.path === window.location.pathname){
                key = menu.key
            }
        })
        if(key){
            return key
        }else{
            return "1"
        }
    }
    const [defaultSelectedKeys,setDefaultSelectedKeys] = useState(getKey());
    // const location = useLocation().pathname;
    const [collapse, setCollapse] = useState(false);
    const [userRole, setUserRole] = useState(getUserRole());

    function handleCollapse()
    {
        setCollapse(!collapse)
    }  
    
    /* callbacks */
    // useEffect(() => {
    //     let elms = document.getElementsByClassName("site-layout");
    //     if (collapse) {
    //         elms[0].style.margin = "0 0 0 0";
    //     } else {
    //         elms[0].style.margin = "0 0 0 200px";
    //     }
    // }, [collapse]);

    return (
        <Sider 
            style={{
                backgroundColor: "#ffff",
                zIndex:100,
            }}
            collapsible 
            breakpoint="lg"
            collapsedWidth="0"
            onCollapse={(collapsed, type) => {
                handleCollapse();
                // console.log(collapsed, type);
            }}
            onBreakpoint={(broken) => {
                handleCollapse();
                // console.log(broken);
            }}
        >
            <div
                style={{
                    padding: 16,
                }}
            >
                <Row className='align-items-center justify-content-center'>
                    <Col span={24}>
                        <img src='images/mmfp.png' width={168}></img>
                    </Col>
                    {/* { collapse && 
                    <Col span={12}>
                        { userRole=="3" ? 
                            <Title level={5} style={{margin:0}}>NGO Guide</Title>
                                : userRole=="4" ?
                                    <Title level={5} style={{margin:0}}>Fellow</Title> : 
                                    userRole=="1" ? 
                                        <Title level={5} style={{margin:0, fontSize:"small"}}>MMFP Admin</Title> :  
                                    userRole=="2" ? 
                                        <Title level={5} style={{fontSize:"15px",margin:0}}>NGO Head</Title> : "Mercy Mission"  
                        }
                    </Col> 
                    } */}
                </Row>
            </div>
            <Menu defaultSelectedKeys={[defaultSelectedKeys]} mode="inline" items={filteredMenuItems}>
            </Menu>
            { collapse && 
                <Menu mode='inline' style={{position:"absolute",bottom:"5px",textAlign:"center"}}>
                <a href="https://aiolos.cloud/" target='_blank' style={{fontSize:"12px", color:"#a4aab2", lineHeight:"0",textDecoration:"none"}} rel='noreferrer'> 
                    © Powered By <br/>Aiolos Cloud Solutions Pvt Ltd
                </a>
                </Menu>
            }
        </Sider>
    )
}
export default Sidebar;