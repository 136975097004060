// import moment from "moment"
// import config from "./config"
// import _ from "lodash"
import axios from 'axios'
import config from "./config";
import apiList from './api_list';

const api_list = require('./api_list');

export const isLogin = () => {
    let userAccessToken = localStorage.getItem('userToken');

    if(userAccessToken)
        return true;
    return false;
}
export const getUserToken = () => {
    return localStorage.getItem('userToken') || null
}

export const isChangePassword = () => {
    let changePassword = localStorage.getItem('cp');

    if(changePassword=='1')
        return true;
    return false;
}

export const getUser = () =>{
    return localStorage.getItem('userName') || null
}

export const getUserRole = () =>{
    return localStorage.getItem('userRole') || null
}

export const api_call = async (api_name,params)=>{

    let url = apiList[api_name].url;
    let method = apiList[api_name].action;
    let authentication = apiList[api_name].authentication ? true : false ;
    let headers = {
        "Content-Type": "application/json",
    };

    if(authentication)
    {
        headers = {
            ...headers,
            "Authorization": `Bearer ${getUserToken()}`
        }
    }
    
    if(method==="get")
    {
        const response = await axios({
            url: `${config.api.base_url}${url}`,
            method: `${method}`,
            params:params,
            headers
        })

        return response;
    }
    else if(method==="post")
    {
        const response = await axios({
            url: `${config.api.base_url}${url}`,
            method: `${method}`,
            data:params,
            headers
        })

        return response;
    }
    
}