import styled from 'styled-components'

export const Container = styled.div`
    height: 50px;
    background-color: white;
    display: flex;
    align-items: center;
    border-bottom: solid 1px #b9b9b940;
    padding: 10px 15px 10px 15px;
    position: sticky;
    top: 0;
    z-index:10;
    justify-content: space-between;
`

export const LeftSection = styled.div`
    margin-right: auto;
    display: flex;
    align-items: center;
    /* flex-flow: wrap; */
`

export const IconContainer = styled.div`
    margin-right: 15px;
    padding: 5px ​20px;
`

export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    flex-flow: wrap;
    /* margin-left: 15px; */
`

export const RightSection = styled.div`
    margin-left: auto;
`
export const Title = styled.span`
    font-family: Helvetica;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #141414;
    margin-right: 5px;
`

export const SubTitle = styled.span`
    font-family: Helvetica;
    font-size: 12px;
    color: ${props => props.theme.colors.textLightGray};
    margin-right: 5px;
`
export const StatusContainer = styled.span`
    color: #796c00;
    font-weight: bold;
    font-family: Helvetica;
    font-size: 12px;
    margin-right: 5px;
`