import { React, useState, useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { api_call } from '../../utils';
import { Row, Col, Modal, notification, Button, Card, Tag, Space, Image, Typography, Form, Input, DatePicker, Popover } from 'antd';
import {Xtable, Xpagination} from '../../component/Xcomponent';
import moment from "moment";
import banner1 from '../../img/banner/1.png';
import banner2 from '../../img/banner/2.png';
import banner3 from '../../img/banner/3.png';
import banner4 from '../../img/banner/4.png';

const { confirm } = Modal;
const { Title } = Typography;
const { RangePicker } = DatePicker;

const Dashboard = () => {
    const [form] = Form.useForm();
    const [rejectForm] = Form.useForm();
    const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);
    const [rejectData, setRejectData] = useState({});
    const [attendanceData, setAttendanceData ] = useState([]);
    const [role, setRole] = useState(localStorage.userRole);
    const [refresh, setRefresh] = useState(0);
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(()=>{
        api_call('get_attendance_list',{
            page:"1",
            records:"10",
            type:"recent",
        }).then((res)=>{
            
            let tableData = [];

            if(res.data.status)
            {
                res.data.data.data.map((item)=>{
                    tableData.push({
                        name: item.user.name,
                        admin: item.admin.name,
                        ngo: item.ngo.name,
                        date: moment(item.attendance_date).format("Do MMM, YYYY"),
                        attendance: item.attendance,
                        status: item.status,
                        remark: item.rejected_remark,
                        id:{
                            id: item.id,
                            status: item.status,
                        }
                    })
                })
            }
            setAttendanceData(tableData);
        })
    },[refresh])

    const columns = [
        {
            title:'Name',
            dataIndex:'name',
            key:'name', 
        },
        {
            title: 'NGO Head',
            dataIndex: 'admin',
            key: 'admin'
        },
        {
            title: 'NGO Guide',
            dataIndex: 'ngo',
            key: 'ngo'
        },
        {
            title:'Date',
            dataIndex:'date',
            key:'date'
        },
        {
            title:'Attendance',
            dataIndex:'attendance',
            key:'attendance',
            render: (text)=>{

                if(text=="present")
                {
                    return  (<Tag color={'green'} key={text}>{text.toUpperCase()}</Tag>)
                }
                else if(text=="absent")
                {
                    return  (<Tag color={'red'} key={text}>{text.toUpperCase()}</Tag>)
                }
                else
                {
                    return "-";
                }
            }
        },
        {
            title:'Status',
            dataIndex:'status',
            key:'status',
            render: (text,record)=>{

                if(text=="approved")
                {
                    return  (<Tag color={'green'} key={text}>{text.toUpperCase()}</Tag>)
                }
                else if(text=="pending")
                {
                    return  (<Tag color={'warning'} key={text}>{text.toUpperCase()}</Tag>)
                }
                else if(text=="rejected")
                {
                    return  (
                        <>
                        <Tag color={'red'} key={text}>{text.toUpperCase()}</Tag>
                        {record.remark && (
                            <Popover
                                content={<div>{record.remark}</div>}
                                title="Rejected Remark"
                                trigger="click"
                        >
                            <Button ghost type="link" size="small">
                                View Remark
                            </Button>
                        </Popover>
                        )}
                    </>
                    )
                }
                else
                {
                    return "-";
                }
            }
        },
        {
            title:'Action',
            key:"id",
            dataIndex:"id",
            render: (data)=>{
                if(data.status=="pending")
                {
                    return <Space size="small">
                        <Button 
                            type="primary" 
                            size="small"
                            onClick={()=>updatedAttendanceStatus('approved',data.id)} 
                        >
                            Approve
                        </Button>
                        <Button 
                            type="primary" 
                            size="small" 
                            danger 
                            onClick={()=>showRejectModal('rejected',data.id)}
                        >
                            Reject
                        </Button>
                    </Space>
                }
            }
        }     
    ]

    const tableColumns = columns.filter(item=>{    
        if(item.key=='name'||item.key=='id')
        {
            if(role==3 || role==2)
            {
                return item;
            } else if(role==1 || role==2 )
            {
                return item;
            } else if(role==1)
            {
                return item;
            }
        } else if (item.key == 'ngo') {
            return role==1 || role == 2; // Only show the column if role is 1
        } else if (item.key == 'admin') {
            return role == 1; 
        } else if(item.dataIndex =='id') {
            return role == 1 && role == 3
        }
        else
        {
            return item;
        }
    })

    const showConfirm = () => {
        confirm({
            title:"Do you want to mark today's attendance",
            onOk() {

                api_call('mark_attendance',{})
                .then((res)=>{
                    if(res.data.status)
                    {
                        setRefresh(refresh+1);
                        notification.success({
                            message: res.data.message,
                        })
                    }
                })
                .catch((error) => {
                    console.log(error);
                    notification.error({
                      message: error.response.data.message ? error.response.data.message  : "Something went wrong!",
                    });
                });
            },
            onCancel() {
                
            },
        })
    }

    const updatedAttendanceStatus = (status,id) => {
        let message = "";
        if(status=="approved")
        {
            message = "Do you want to approve this attendance?";
        confirm({
            title:message,
            onOk() {
                api_call('update_attendance_status',{
                    atid:id,
                    status
                })
                .then((res)=>{
                    if(res.data.status)
                    {
                        setRefresh(refresh+1);
                        notification.success({
                            message: res.data.message,
                        });
                    }
                })
            },
            onCancel() {    
            },
        })
        }
    }

    const handleReject = (value) => {
        if(value.remark && rejectData.status==="rejected" && rejectData.id){
        api_call('update_attendance_status',{
            atid:rejectData.id,
            status:rejectData.status,
            rejected_remark:value.remark,
        })
        .then((res)=>{
            if(res.data.status)
            {
                setRefresh(refresh+1);
                notification.success({
                    message: res.data.message,
                });
                handleCloseRejectModal();
            }
        })
    }
    }

    const showRejectModal = (status,id) => {
        setIsRejectModalVisible(true);
        setRejectData({status,id})
    };

    const handleCloseRejectModal = () => {
        setIsRejectModalVisible(false)
        rejectForm.resetFields()
        rejectForm.setFieldsValue({});
        setRejectData({});
    }

    const showModal = (data = {}) => {
        setIsModalVisible(true);
        form.setFieldValue({})
    };

    const handleCloseModal = () => {
        setIsModalVisible(false)
        form.resetFields()
        form.setFieldsValue({});
    }

    const submitLeave = (value) => {
        if(value){
            let data = {
                start_date: value.day ? value.day[0].format("YYYY-MM-DD") : "",
                end_date: value.day ? value.day[1].format("YYYY-MM-DD") : "",
                reason: value.reason ? value.reason : "",
            }
            api_call('mark_leave',data)
                .then((res)=>{
                    if(res.data.status)
                    {
                        setRefresh(refresh+1);
                        handleCloseModal();
                        notification.success({
                            message: res.data.message,
                        })
                    }
                })
                .catch((error) => {
                    console.log(error);
                    handleCloseModal()
                    notification.error({
                      message: error.response.data.message ? error.response.data.message  : "Something went wrong!",
                    });
                });
                console.log(data,"dansih")
            }
    }

    // Function to disable previous dates
    const disabledDate = (current) => {
        // Get the start of the current day (today)
        const startOfCurrentDay = new Date();
        startOfCurrentDay.setHours(0, 0, 0, 0);
        // Disable dates before the start of the current day
        return current && current < startOfCurrentDay;
    };

    return (
        <div style={{padding:"15px"}}>
            {/* Reject Modal */}
                <Modal
                    title="Enter Remark"
                    open={isRejectModalVisible}
                    onCancel={handleCloseRejectModal}
                    footer={[ <>
                        <Button key="back" onClick={handleCloseRejectModal} >
                            Cancel
                        </Button>,
                        <Button button key="submit" danger type="primary" htmlType="submit" form="reject-form">
                            Reject
                        </Button>
                    </>
                    ]}
                >
                    <Form
                        id="reject-form"
                        form={rejectForm}
                        name="reject-form"
                        onFinish={handleReject}
                    > <br/>
                        <Form.Item
                            className="form-label label"
                            name="remark"
                            label="Remark"
                            labelCol={{span: 24}}
                            rules={[
                            {
                                required: true,
                                message: 'Please enter remark',
                            },
                            ]}
                        >
                            <Input.TextArea showCount maxLength={40} size="large" allowClear name="remark" placeholder="Enter Remark for Rejection"/>
                        </Form.Item>
                    </Form>
                </Modal>
            {role == 4 && 
                <Row gutter={22} style={{margin:"10px 0 10px 0"}}>
                    <Col
                        sm={{offset:22,span:2}}
                        md={{offset:20,span:4}}
                        lg={{offset:20,span:4}}
                        xl={{offset:20,span:4}}
                        className='d-flex justify-content-end p-0'
                    >
                        <Space direction=''>
                            <Button type="primary" onClick={showConfirm} >Mark Attendance</Button>
                            <Button type="primary" onClick={showModal} style={{width:"138px"}}>Apply for Leave</Button>
                        </Space>
                    </Col>
                    <Modal
                        title="Apply for leave"
                        open={isModalVisible}
                        onCancel={handleCloseModal}
                        footer={[
                            <Button key="back" onClick={handleCloseModal}>
                                Cancel
                            </Button>,
                            <Button button key="submit" type="primary" htmlType="submit" form="leave-form">
                                Apply
                            </Button>
                        ]}
                    >
                        <Form
                            id="leave-form"
                            form={form}
                            name="leave-form"
                            onFinish={submitLeave}
                        ><br/>
                        <Form.Item
                            className="form-label label"
                            name="reason"
                            label="Reason"
                            labelCol={{span: 24}}
                            rules={[
                            {
                                required: true,
                                message: 'Please enter a reason',
                            },
                            ]}
                        >
                            <Input.TextArea showCount maxLength={100} size="large" allowClear name="reason" placeholder="Enter Reason for Leave"/>
                        </Form.Item>
                        <Form.Item
                            className="form-label label"
                            name="day"
                            label="Date"
                            labelCol={{span: 24}}
                            rules={[
                            {
                                required: true,
                                message: 'Please select a date',
                            },
                            ]}
                        >
                            <RangePicker disabledDate={disabledDate} format={"DD-MMM-YYYY"} allowClear/>
                        </Form.Item>
                        </Form>
                    </Modal>
                </Row>
            }

            {/* <Row gutter={[10,10]}>
                <Col 
                    xs={24}
                    sm={22}
                    md={12}
                    lg={8}
                    xl={6} 
                >
                    <Card>
                        <Card.Grid 
                            className="noBoxShadow"
                            style={{
                                width:"50%", 
                                border:"none",
                            }}
                        >
                            <Title level={5} style={{fontWeight:"bold",margin:"0px"}}>Projects</Title>
                            <Title level={4} style={{margin:"0px", fontWeight: "bolder", fontSize:"32px"}}>256</Title>
                        </Card.Grid>
                        <Card.Grid 
                            className="noBoxShadow"
                            style={{
                                width:"50%", 
                                border:"none"
                            }}
                        >
                            <Image preview={false} src={banner1} height={100}></Image>
                        </Card.Grid>
                    </Card>    
                </Col>
                <Col 
                    xs={24}
                    sm={22}
                    md={12}
                    lg={8}
                    xl={6}  
                >
                    <Card>
                        <Card.Grid 
                            className="noBoxShadow"
                            style={{
                                width:"50%", 
                                border:"none",
                            }}
                        >
                            <Title level={5} style={{fontWeight: "bold",margin:"0px"}}>Attendance</Title>
                            <Title level={2} style={{margin:"0px", fontWeight: "bolder", fontSize:"32px"}}>75%</Title>
                        </Card.Grid>
                        <Card.Grid 
                            className="noBoxShadow"
                            style={{
                                width:"50%", 
                                border:"none"
                            }}
                        >
                            <Image preview={false} src={banner2} height={100}></Image>
                        </Card.Grid>
                    </Card>    
                </Col>
                { (role=="3" ||role=="2" ||role=="1")&& 
                    <Col
                        xs={24} 
                        sm={22}
                        md={12}
                        lg={8}
                        xl={6} 
                    >
                        <Card>
                            <Card.Grid 
                                className="noBoxShadow"
                                style={{
                                    width:"50%", 
                                    border:"none",
                                }}
                            >
                                <Title level={5} style={{fontWeight: "bold",margin:"0px"}}>Fellows</Title>
                                <Title level={2} style={{margin:"0px", fontWeight: "bolder", fontSize:"32px"}}>12</Title>
                            </Card.Grid>
                            <Card.Grid 
                                className="noBoxShadow"
                                style={{
                                    width:"50%", 
                                    border:"none"
                                }}
                            >
                                <Image preview={false} src={banner3} height={100}></Image>
                            </Card.Grid>
                        </Card>    
                    </Col>
                }
                <Col
                    xs={24} 
                    sm={22}
                    md={12}
                    lg={8}
                    xl={6}  
                >
                    <Card>
                        <Card.Grid 
                            className="noBoxShadow"
                            style={{
                                width:"50%", 
                                border:"none",
                            }}
                        >
                            <Title level={5} style={{fontWeight: "bold",margin:"0px"}}>Completed</Title>
                            <Title level={2} style={{margin:"0px", fontWeight: "bolder", fontSize:"32px"}}>10</Title>
                        </Card.Grid>
                        <Card.Grid 
                            className="noBoxShadow"
                            style={{
                                width:"50%", 
                                border:"none"
                            }}
                        >
                            <Image preview={false} src={banner4} height={100}></Image>
                        </Card.Grid>
                    </Card>
                </Col>
            </Row> */}

            <Row>
              <Col span={24}>
                <Card
                    title="Recent Attendance"
                    style={{
                        width:"100%",
                        minHeight:"300px",
                        marginTop:"10px",
                    }}
                >
                    <Xtable
                        columns={tableColumns}
                        dataSource={attendanceData}
                        pagination={false}
                        scroll={{x: 768}}
                        style={{
                            minHeight:"300px",
                        }}
                    />
                    
                </Card>
              </Col>  
            </Row>
              
        </div>
    )
}

export default Dashboard;
