import React, { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MenuUnfoldOutlined, MenuFoldOutlined, UserOutlined } from "@ant-design/icons";

// components
import {
    Container,
    LeftSection,
    RightSection,
    Title,
    TitleContainer,
} from "./HeaderStyle";
import { getUserRole } from '../../utils'
import { api_call, getUser } from '../../utils'
import { Avatar, Popover, Space, Button, notification, Col } from "antd";

const Header = props => {
    const [userRole, setUserRole] = useState(getUserRole());

    const navigate = useNavigate();

    let user = getUser() || "User"
    const profilePopover = () => {
        return (
            <>
                <p>Welcome {user}</p>
                <Space>
                    <Button onClick={logoutUser}>Logout</Button>
                </Space>
            </>
        )
    }

    const logoutUser = () => {
        try{
            api_call('logout',{})
            localStorage.removeItem('user')
            localStorage.removeItem('userToken')
            localStorage.removeItem('userRole')
            localStorage.removeItem('cp')
            notification.success({
                message: "You are logged out successfully.",
                placement: "bottomRight"
            });

            navigate('/login');
        }catch(error){
            console.log(error.message)
        }
    }

    return (
        <>
            <Container>
                <div style={{marginLeft:"35px"}}>
                        { userRole=="3" ? 
                            <Title level={5} style={{margin:0}}>NGO Guide</Title>
                                : userRole=="4" ?
                                    <Title level={5} style={{margin:0}}>Fellow</Title> : 
                                    userRole=="1" ? 
                                        <Title level={5} style={{margin:0, fontSize:"small"}}>MMFP Admin</Title> :  
                                    userRole=="2" ? 
                                        <Title level={5} style={{fontSize:"15px",margin:0}}>NGO Head</Title> : "Mercy Mission"  
                        }
                </div>
                <Space size={"small"} style={{ alignItems: "end" }}>
                    {props.rightChildren}
                    <Popover
                        placement="bottomRight"
                        content={profilePopover}
                        trigger='click'
                    >
                        <Avatar icon={<UserOutlined />} />
                    </Popover>
                </Space>
            </Container>
        </>
    );
};

export default Header;
