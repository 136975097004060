const apiList = {
    login:{
        action:"post",
        url:"login",
        authentication:false
    },
    changePassword:{
        action:"post",
        url:"change-password",
        authentication:true
    },
    logout:{
        action:"post",
        url:"logout",
        authentication:true
    },
    fetch_fellow_list:{
        action:"get",
        url:"fellow/list",
        authentication:true
    },
    fetch_ngo_admin_list:{
        action:"get",
        url:"ngoAdmin/list",
        authentication:true
    },
    fetch_ngo_list:{
        action:"get",
        url:"ngo/list",
        authentication:true
    },
    get_attendance_list:{
        action:"get",
        url:"attendance/list",
        authentication:true
    },
    get_fellow_attendance_list:{
        action:"get",
        url:"attendance/fellow-attendance-list",
        authentication:true
    },
    update_attendance_status:{
        action:"post",
        url:"attendance/update-attendance-status",
        authentication:true
    },
    mark_attendance:{
        action:"post",
        url:"attendance/mark-attendance",
        authentication:true
    },
    get_holiday:{
        action:"get",
        url:"holidays/list",
        authentication:true
    },
    post_holiday:{
        action:"post",
        url:"holidays/add-holiday",
        authentication:true
    },
    get_week_off:{
        action:"get",
        url:"weekoff/list",
        authentication:true
    },
    post_week_off:{
        action:"post",
        url:"weekoff/mark-weekoff",
        authentication:true
    },
    get_compensation:{
        action:"get",
        url:"compensation/list",
        authentication:true
    },
    ngo_admin_details:{
        action:"get",
        url:"usersDetail/ngo-admin",
        authentication:true
    },
    ngo_guide_details:{
        action:"get",
        url:"usersDetail/ngo-guide",
        authentication:true
    },
    fellow_details:{
        action:"get",
        url:"usersDetail/fellow",
        authentication:true
    },
    fetch_ngos_list:{
        action:"get",
        url:"ngos/list",
        authentication:true
    },
    ngos_detail:{
        action:"get",
        url:"usersDetail/ngos",
        authentication:true
    },
    mark_leave:{
        action:"post",
        url:"leave/mark-leave",
        authentication:true
    },
    get_leave_list:{
        action:"get",
        url:"leave/list",
        authentication:true
    },
    update_leave_status:{
        action:"post",
        url:"leave/update-leave-status",
        authentication:true
    },
    get_fellow_leave_list:{
        action:"get",
        url:"leave/fellow-leave-list",
        authentication:true
    },
}

export default apiList;