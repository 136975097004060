import {React,useEffect,useState} from 'react';
import { Menu, Layout, Space, Row, Col } from 'antd';
import { redirect ,useNavigate} from "react-router-dom";
import { api_call } from '../utils';
import {
    AppstoreOutlined,
    BookOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    SettingOutlined,
    UserOutlined,
    CalendarOutlined,
    WechatOutlined,
    HomeOutlined,
    ScheduleOutlined
  } from "@ant-design/icons";
import {Alert, Button, Form, Input} from 'antd';

const ChangePassword = (props) =>{

    const { userData } = props;
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();

    const changeUserPassword = (values) => {

        let data = {
          new_password: values.new_password,
          confirm_password: values.confirm_password,
        }    
    
        api_call('changePassword',data).then((res)=>{
          if(res.data.status)
          {
            localStorage.setItem("userName",userData.name);
            localStorage.setItem("userRole",userData.role);
            localStorage.setItem("cp","0");
            navigate('/');
          }
          else
          {
            setErrorMessage(res.data.message);
          }  
        })
      } 
    const changeUserPasswordFailed = ()=>{};

    return (
        <div className="col-12 col-sm-12 col-lg-5 pl-md-5 mt-sm-4 mb-sm-4">
            <img className="img-fluid mb-5" src="images/logo.svg" alt=""/>
            <h1 className="loginHeading1 mb-2">Change Password </h1>
            <h3 className="loginHeading3 mb-5">as you have logged in for the first time</h3>
            {
              errorMessage!="" && 
              (
                <Alert
                  style={{ marginBottom: 8 }}
                  message={errorMessage}
                  type="error"
                  showIcon
                />
              )
            }
            <Form
              layout="vertical"
              size="large"
              name="login_form"
              onFinish={changeUserPassword}
              onFinishFailed = {changeUserPasswordFailed}
            >
                <Form.Item
                    name="new_password"
                    label="New Password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your new password!',
                        },
                    ]}
                    hasFeedback
                    style={{paddingBottom:"10px"}}
                >
                    <Input.Password />
                </Form.Item>
  
                <Form.Item
                    name="confirm_password"
                    label="Confirm Password"
                    dependencies={['newPassword']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Please confirm your new password!',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('new_password') == value) 
                                { 
                                    return Promise.resolve();          
                                }          
                                return Promise.reject(new Error('The two passwords that you entered do not match!'),);        
                            },      
                        }),    
                    ]}
                    style={{paddingBottom:"10px"}}
                >
                    <Input.Password />
                </Form.Item>

              <Form.Item>
                <Button htmlType="submit" className="btn btn-primary btn-lg btn-block" style={{height:"50px"}}>
                  Change Password
                </Button>
              </Form.Item>
            </Form>
        </div>
    )
}
export default ChangePassword;