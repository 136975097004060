import React, {useEffect, useState}from 'react';
import { Calendar, Button, Form, Modal, Card,Select,DatePicker, Col, Row,Typography, Checkbox, Input, notification} from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import { api_call, getUser } from '../../utils';
import moment from 'moment';
import _ from "lodash";

const { Title } = Typography;

const Holiday = () => {
  let user = getUser() || "User";
  const [role, setRole] = useState(localStorage.userRole);
  const [form] = Form.useForm();
  const [weekForm] = Form.useForm();
  const [filterForm] = Form.useForm();
  const [isHolidayModalVisible, setIsHolidayModalVisible] = useState(false);
  const [isWeekModalVisible, setIsWeekModalVisible] = useState(false);
  const [date, setDate] = useState(new Date());
  const [datelist, setDateList] = useState([]);
  const [weekoff, setWeekoff] = useState({});
  const [ngoList, setNgosList] = useState([]);
  const [selectedNgoId, setSelectedNgoId] = useState("");
  const [selectedWeek, setSelectedWeek] = useState([]);
  const [holidayName, setHolidayName] = useState("");
  const weekOptions = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  const fetchHolidaysList = (search) =>{
    api_call('get_holiday',search)
    .then((res)=>{
      setDateList(res.data.data);
    });
  }

  const fetchWeekoffList = (search) =>{
    api_call('get_week_off',search)
    .then((res)=>{
      setWeekoff(res.data.data[0]);
    });
  }

  const addHolidays = (search) =>{
    api_call('post_holiday',search)
    .then((res)=>{
      setIsHolidayModalVisible(false)
      if(res.data.status) {
        notification.success({
          message: res.data.message ? res.data.message : "Holiday created successfully",
        });
        submitFilter(search)
      } 
    }
    ).catch((error) => {
      console.log(error);
      notification.error({
        message: error.response.data.message ? error.response.data.message  : "Something went wrong!",
    });
    });
  }

  const addWeekoff = (search) =>{
    api_call('post_week_off',search)
    .then((res)=>{
      setIsWeekModalVisible(false)
      if(res.data.status) {
        notification.success({
          message: res.data.message ? res.data.message : "Week-off updated successfully",
        });
        submitFilter(search)
      }
    }).catch((error) => {
      console.log(error);
      notification.error({
        message: error.response.data.message ? error.response.data.message : "Something went wrong!",
      });
    });
  }

  const submitFilter = (value) => {
    if(value.admin ) {
      let data = {
          ngoId: value.admin ? value.admin : "",
      }
      fetchHolidaysList(data)
      fetchWeekoffList(data)
      filterForm.setFieldsValue({admin:value.admin})
    } 
    if (value.ngoId) {
      let data = {
        ngoId: value.ngoId ? value.ngoId : "",
      }
      fetchHolidaysList(data)
      fetchWeekoffList(data)
      filterForm.setFieldsValue({admin:value.ngoId})
    }
    else {
      setDateList([])
      setWeekoff({})
    }
    if(role==2){
      fetchHolidaysList({})
      fetchWeekoffList({})
    }
  }

  const showHolidayModal = (data = {}) => {
    setIsHolidayModalVisible(true);
    // form.setFieldValue({});
  };
  
  const showWeekModal = (data = {}) => {
    setIsWeekModalVisible(true);
    // form.setFieldValue({});
  };

  const handleCloseModal = () => {
    setIsHolidayModalVisible(false);
    form.resetFields();
    // form.setFieldValue({});
  };

  const handleWeekCloseModal = () => {
    setIsWeekModalVisible(false);
    weekForm.resetFields();
    setSelectedWeek([]); // Clear the selected options when the modal is closed
    // form.setFieldValue({});
  };

  const handleCalendarChange = (value) => {
    setDate(value.toDate());
  };

  // Function to disable previous dates
  const disabledDate = (current) => {
    // Get the start of the current day (today)
    const startOfCurrentDay = new Date();
    // startOfCurrentDay.setHours(0, 0, 0, 0);
    // Disable dates before the start of the current day
    return current && current <= startOfCurrentDay;
  };

  const onFinish = (value) => {
    if (value) {
      let data = {
        ngoId: value.modalAdmin ? value.modalAdmin : "",
        day: date ? date : "",
        holidayName: value.name ? value.name : "",
      }
      addHolidays(data)
      handleCloseModal()
    }
  };

  const onWeekFinish = (value) => {
    if (value) {
      let data = {
        ngoId: value.modalAdmin ? value.modalAdmin : "",
        weekOff: value.weekoff ? value.weekoff : "",
      }
      addWeekoff(data)
      handleWeekCloseModal()
    }
  };

  // Function to render custom content for holiday dates
  function dateCellRender(date) {
    const filteredDates = datelist.filter((holiday) => holiday.day === date.format('YYYY-MM-DD'));
    // const isWeekoff = Object.values(weekoff).some((item) => item.week_off_day.toLowerCase() === date.format('dddd').toLowerCase());
    // const isWeekoff = weekoff && weekoff.week_off_day === date.format('dddd').toLowerCase();
    const isWeekoff = weekoff && weekoff.week_off_days && weekoff.week_off_days?.includes(date.format('dddd').toLowerCase()); //todo: change the week_off_days to weeks_off_day
    return (
      <div>      
        <a className="fc-day-grid-event fc-h-event fc-event fc-start fc-end fc-draggable">
        {isWeekoff && (
          <div className="fc-content" style={{ fontSize: "10px", backgroundColor: "#fe9701", borderRadius: "3px", padding: "2px", marginBottom: "4px" }}>
            <span className="fc-title">Weekoff</span>
          </div>
        )}
        {filteredDates.map((d) => (
            <div className="fc-content" style={{ fontSize: "10px", backgroundColor: "#F3565D", borderRadius: "3px", padding: "2px", marginBottom: "4px" }} key={d.id}>
              <span className="fc-title">{d.holiday?d.holiday:"Holiday"}</span>
            </div>
        ))}
        </a>
      </div>
    );
  }

  function monthCellRender(date) {
    const formattedDate = date.format('YYYY-MM');
    const holidaysInMonth = datelist.filter((holiday) => holiday.day.startsWith(formattedDate));
    return (
      <div>
        <a className="fc-day-grid-event fc-h-event fc-event fc-start fc-end fc-draggable" >
        {holidaysInMonth.length > 0 ? (
          <div className="fc-content" style={{ fontSize: "14px", backgroundColor: "#F3565D", borderRadius: "3px", padding: "2px"}}>
            {holidaysInMonth.length} {holidaysInMonth.length === 1 ? 'holiday' : 'holidays'}
          </div>
        ) : null}
        </a>
      </div>
    );
  }

  useEffect(() => {
    if(role == 3 || role == 2 || role == 4) {
      fetchHolidaysList({})
      fetchWeekoffList({})
    }
  }, []);

  return(
  <>
  <Card style={{margin:"16px"}}>
    <Row gutter={24} >
      <Col span={24}>
      {role == 1 && 
        <Form
          // layout="inline"
          form={filterForm}
          size="medium"
          name="filter_form"
          onFinish={submitFilter}
          style={{width:"100%"}}
        >
          <Row gutter={24}>
            <Col sm={24} md={10} lg={10} xl={6} >
              <Form.Item
                name="admin"
                className="form-label label custom-form-item"
                label="Select NGO"
                wrapperCol={24}
                // style={{marginBottom:"0"}}
              >
                <Select
                  name="admin"
                  showSearch
                  placeholder="Select NGO"
                  allowClear
                  filterOption={false}
                  onFocus={_.debounce((v)=>{
                    api_call('fetch_ngos_list',{})
                    .then((res)=>{
                        setNgosList([])
                        if (res.data.status) {
                          setNgosList(res.data.data)
                        }
                    })
                },500)}
                onSearch={_.debounce((v)=>{
                    api_call('fetch_ngos_list',{search:v})
                    .then((res)=>{
                        setNgosList([])
                        if (res.data.status) {
                          setNgosList(res.data.data)
                        }
                    })
                },500)}
                  onChange={(value) => {setSelectedNgoId(value)}}
                >
                  {ngoList.length>0 && 
                    ngoList.map(item=>(
                      <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col sm={24} md={5} lg={5} xl={6}>
              <Form.Item
              // style={{marginBottom:"0"}}
              >
                <Button htmlType="submit" type="primary" style={{width:"90px"}}>Go</Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      }

      {(role == 2 || role ==3 || role == 4) && (
      <>
        <Title level={3} className='mb-0'>Holidays</Title>
      </>
      )}
      </Col>
    </Row>
  </Card>

  <Row>
  {(role == 1 || role ==2) && (
		<div className="col-12 col-sm-12 col-lg-12 col-xl-3">
			<div className="card" style={{boxShadow:"none"}}>
					<div className="row p-3">
					<div className="col-12 d-flex align-items-center flex-column">
            <Title level={4}>Set Public Holiday</Title>
            <Button type="primary" onClick={showWeekModal} className="d-block w-75 mb-2">
              Set Week Off
            </Button>
            <Button type="primary" onClick={showHolidayModal} className="d-block w-100 mb-3">
              Set Holiday
            </Button>
          </div>
						<div className="col-12">
							<div id="dateCalendar">
                <Calendar fullscreen={false} onChange={handleCalendarChange} disabledDate={disabledDate}/>
              </div>
					  </div>	
				  </div>
			</div>

      {/* Holiday Modal */}
      <Modal
        title="Set Holiday"
        onCancel={handleCloseModal}
        open={isHolidayModalVisible}
        footer={[
          <Button key="back" onClick={handleCloseModal}>
            Cancel
          </Button>,
          <Button key="submit" htmlType="submit" form='holiday-form' type="primary">
            Save
          </Button>
        ]}
      >
      <Form
        form={form}
        id="holiday-form"
        size='large'
        name="holiday-form"
        onFinish={onFinish}
      >
        <br />
        {role == 1 && 
          <Form.Item
            className="form-label label custom-form-item"
            name="modalAdmin"
            label="Select NGO"
            labelCol={{span: 24}}
            style={{marginBottom:"10px"}}
            rules={[
              {
                required: true,
                message: 'Please select NGO',
              },
            ]}
          >
            <Select
              name="modalAdmin"
              showSearch
              placeholder="Select NGO"
              allowClear
              // defaultValue={selectedNgoId ? selectedNgoId:""}
              filterOption={false}
              onFocus={_.debounce((v)=>{
                api_call('fetch_ngos_list',{})
                .then((res)=>{
                    setNgosList([])
                    if (res.data.status) {
                      setNgosList(res.data.data)
                    }
                })
              },500)}
              onSearch={_.debounce((v)=>{
                  api_call('fetch_ngos_list',{search:v})
                  .then((res)=>{
                      setNgosList([])
                      if (res.data.status) {
                        setNgosList(res.data.data)
                      }
                  })
              },500)}
              onChange={(value) => {setSelectedNgoId(value)}}
            >
            {/* {ngoList.length>0 && 
              ngoList.map(item=>(
                <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
              ))
            } */}
             {ngoList.length > 0 && (
              <>
                <Select.Option value="null">For All</Select.Option>
                {ngoList.map((item) => (
                  <Select.Option value={item.id} key={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </>
            )}
            </Select>
          </Form.Item>
        }
        <Form.Item
            className="form-label label custom-form-item"
            name="name"
            label="Holiday Name"
            rules={[
              {
                required: selectedNgoId == "null",
                message: "Please enter Holiday Name",
              },
            ]}
            labelCol={{span: 24}}
            style={{marginBottom:"20px"}}
          >
            <Input allowClear name="name" placeholder="Enter Holiday Name" onChange={(e) => {setHolidayName(e.target.value)}} />
        </Form.Item>
        <Form.Item
          className="form-label label custom-form-item"
          style={{marginBottom:"10px"}}
        >
          <div className="row align-items-center">
            <div className="col-12 col-sm-1 ml-1">
                <CalendarOutlined style={{fontSize:"35px"}}/>
            </div>
            <div className="col-12 col-sm-5 mt-sm-0 ml-2">
                <li>{date.toDateString()}</li>
            </div>
          </div>
        </Form.Item>
      </Form>
      </Modal>

      {/* WeekOff Modal */}
      <Modal
        title="Set Week Off"
        onCancel={handleWeekCloseModal}
        open={isWeekModalVisible}
        footer={[
          <Button key="back" onClick={handleWeekCloseModal}>
            Cancel
          </Button>,
          <Button key="submit" htmlType="submit" form='weekoff-form' type="primary">
            Save
          </Button>
        ]}
      >
      <Form
        form={weekForm}
        id="weekoff-form"
        size='large'
        name="weekoff-form"
        onFinish={onWeekFinish}
      >
        <br />
        {role == 1 && 
          <Form.Item
            className="form-label label custom-form-item"
            name="modalAdmin"
            label="Select NGO"
            labelCol={{span: 24}}
            style={{marginBottom:"10px"}}
            rules={[
              {
                required: true,
                message: 'Please select NGO',
              },
            ]}
          >
            <Select
              name="modalAdmin"
              showSearch
              placeholder="Select NGO"
              allowClear
              // defaultValue={selectedNgoId ? selectedNgoId:""}
              filterOption={false}
              onFocus={_.debounce((v)=>{
                api_call('fetch_ngos_list',{})
                .then((res)=>{
                    setNgosList([])
                    if (res.data.status) {
                      setNgosList(res.data.data)
                    }
                })
              },500)}
              onSearch={_.debounce((v)=>{
                  api_call('fetch_ngos_list',{search:v})
                  .then((res)=>{
                      setNgosList([])
                      if (res.data.status) {
                        setNgosList(res.data.data)
                      }
                  })
              },500)}
              onChange={(value) => {setSelectedNgoId(value)}}
            >
            {ngoList.length>0 && 
              ngoList.map(item=>(
                <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
              ))
            }
            </Select>
          </Form.Item>
        }
        <Form.Item
          className="form-label label custom-form-item"
          name="weekoff"
          label="Week Off"
          labelCol={{span: 24}}
          style={{marginBottom:"30px"}}
          rules={[
            {
              required: true,
              message: 'Please select week off',
            },
          ]}
        >
          <Select name="weekoff" 
            mode='multiple' 
            onChange={(value) => {setSelectedWeek(value)}} 
            showSearch 
            placeholder="Select Week off"
            allowClear
          >
            {weekOptions.map((day) => (
            <Select.Option
              key={day}
              disabled={selectedWeek.length >= 2 && !selectedWeek.includes(day)}
              value={day}
            >
              {day.charAt(0).toUpperCase() + day.slice(1)}
            </Select.Option>
          ))}
          </Select>
        </Form.Item>
      </Form>
      </Modal>
		</div>
  )}
  {(role == 1 || role == 2) && (
  <div className="col-12 col-sm-12 col-lg-12 col-xl-9">
    <Card>
      <div className="fc-overflow">
        <div id="myEvent" className="manageSchCal">
          <Calendar dateCellRender={dateCellRender} monthCellRender={monthCellRender}/>
        </div>
      </div>
    </Card>
  </div>
  )}
  {(role == 3 || role == 4) && (
  <div className="col-12 col-sm-12 col-lg-12 col-xl-12">
    <Card>
      <div className="fc-overflow">
        <div id="myEvent" className="manageSchCal">
          <Calendar dateCellRender={dateCellRender} monthCellRender={monthCellRender}/>
        </div>
      </div>
    </Card>
  </div>
  )}
	</Row>
  </>
  )
};

export default Holiday;