import { React, useState, useEffect } from 'react';
import { api_call } from '../../utils';
import { Row, Col, Button, Card, DatePicker, Form,Popconfirm } from 'antd';
import {Xtable, Xpagination} from '../../component/Xcomponent';
import {UploadOutlined} from '@ant-design/icons';
import { CSVLink } from 'react-csv';

import moment from "moment";
import _ from "lodash";

const Accounts = (props) => {
    const [form] = Form.useForm();
    const [role, setRole] = useState(localStorage.userRole);
    const [compensationList, setCompensationList] = useState([]);
    const [compensationExportData, setCompensationExportData] = useState([]);
    const headers = [
        { label: 'Name', key: 'name' },
        { label: 'Month', key: 'month' },
        { label: 'Working Days', key: 'working' },
        { label: 'Holidays', key: 'holidays' },
        { label: 'Days Present', key: 'present' },
        { label: 'Leaves', key: 'leaves' },
        { label: 'Week Offs', key: 'weekoff' },
        { label: 'Days Worked', key: 'worked' },
        { label: 'Per Day Rate', key: 'rate' },
        { label: 'Stipend', key: 'salary' },
        { label: 'Compensation', key: 'compensation' },
    ];

    const fetchCompensationList =(search) => {
        api_call('get_compensation',search)
        .then((res)=>{
            let tableData = [];
            if(res.data.status){
                res.data.data.map((item) => {
                    tableData.push({
                        name: item.fellow.name,
                        month: item.monthName,
                        working: item.workingDays,
                        holidays: item.uniqueHolidays,
                        present: item.daysPresent,
                        leaves: item.leaves,
                        weekoff: item.weekOffDaysCount,
                        worked: item.totalDaysWorked,
                        rate: item.perDayRate,
                        salary: item.fellow.salary,
                        compensation:item.actualSalary,
                    })
                })
            }
            setCompensationList(tableData);
            setCompensationExportData(tableData); // Set the export data
        })
    }

    useEffect(()=>{
        if(role==1 || role==2){
        fetchCompensationList({})
        }
    },[])

    const columns = [
        {
            title:'Name',
            dataIndex:'name',
            key:'name'
        },
        {
            title:'Month',
            dataIndex:'month',
            key:'month'
        },
        {
            title: 'Working Days',
            dataIndex: 'working',
            key: 'working'
        },
        {
            title: 'Holidays',
            dataIndex: 'holidays',
            key: 'holidays'
        },
        {
            title: 'Days Present',
            dataIndex: 'present',
            key: 'present'
        },
        {
            title: 'Leaves',
            dataIndex: 'leaves',
            key: 'leaves'
        },
        {
            title: 'Week Offs',
            dataIndex: 'weekoff',
            key: 'weekoff'
        },
        {
            title: 'Days Worked',
            dataIndex: 'worked',
            key: 'worked'
        },
        {
            title: 'Per Day Rate',
            dataIndex: 'rate',
            key: 'rate',
            render: (rate) => <span>&#8377; {rate}</span>,
        },
        {
            title: 'Stipend',
            dataIndex: 'salary',
            key: 'salary',
            render: (salary) => <span>&#8377; {salary}</span>,
        },
        {
            title: 'Compensation',
            dataIndex: 'compensation',
            key: 'compensation',
            render: (compensation) => <span>&#8377; {compensation}</span>,
        },
    ]

    const onMonthChange = (value) => {
        fetchCompensationList({selectedMonth:value.format('YYYY-MM')});
    }

    const submitFilter = (value) => {
        let data = {
            selectedMonth: value.filter_month_year ? value.filter_month_year.format("YYYY-MM") : "",
        }
        fetchCompensationList(data)
    }

    const disabledFutureMonths = (current) => {
        return current && current > moment().endOf('month');
    }

    return ( <>
       {(role == 1 || role ==2) && ( <>
            <Row gutter={{offset:1, span:23}} style={{margin:"10px"}}>
              <Col span={24}>
                <Card
                    title="Accounts"
                    style={{
                        width:"100%",
                        minHeight:"100px",
                    }}
                >
                <Row gutter={24} style={{marginBottom:"20px"}}>
                    <Col span={24}>
                        <Form
                            form={form}
                            size="medium"
                            name="filter_form"
                            onFinish={submitFilter}
                            style={{width:"100%"}}
                        >
                        <Row gutter={24}>
                            <Col sm={12} md={7} lg={5} xl={3}>
                                <Form.Item
                                    name="filter_month_year"
                                    wrapperCol={24}
                                >
                                    <DatePicker placeholder="Select Month" picker="month" disabledDate={disabledFutureMonths}></DatePicker>
                                </Form.Item>
                            </Col>
                            <Col sm={22} md={5} lg={3} xl={2} >
                                <Form.Item>
                                    <Button htmlType="submit" type="primary" style={{width:"80px"}}>Go</Button>
                                </Form.Item>
                            </Col>
                            <Col sm={24} md={5} lg={5} xl={5} >
                            <Form.Item>
                                <Popconfirm
                                    title="Confirm download ?"
                                    okText={
                                        <CSVLink
                                            data={compensationExportData}
                                            headers={headers}
                                            filename={`Fellows Compensation Data.csv`}
                                            type="ghost"
                                        >
                                            Yes
                                        </CSVLink>
                                    }
                                    cancelText="No"
                                    okType="primary"
                                    >
                                    <Button className='d-flex align-items-center' icon={<UploadOutlined style={{fontSize:"20px"}}/>}>
                                        Export
                                    </Button>
                                </Popconfirm>
                            </Form.Item>
                            </Col>
                        </Row>
                        </Form>
                    </Col>
                </Row>
                <Xtable
                    columns={columns}
                    dataSource={compensationList}
                    pagination={true}
                    scroll={{x: 768}}
                    style={{
                        minHeight:"200px",
                    }}
                />
                {/* <Xpagination
                    pageSizeOptions={["10", "25", "50", "100", "500"]}
                    showSizeChanger
                    total={totalRecords}
                    onChange={(page, pageSize) =>
                        setPagination({ page: page, records: pageSize })
                    }
                    current={pagination.page}
                    defaultPageSize={pagination.records}
                    defaultCurrent={1}
                /> */}
                </Card>
              </Col>
            </Row> 
            </>
        )}
       </> 
    );
}

export default Accounts