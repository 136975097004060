import {Pagination, Table} from 'antd';
import styled, {css} from 'styled-components';

export const Xtable = styled(Table)`
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  .ant-table-thead > tr > th {
    font-weight: bold;
    color: rgb(0 0 0 / 1);
    /* background-color: #ffffff; */
    background: rgba(240, 241, 248, 0.8);
    font-size: 13px;
    padding: 0.5rem 1rem;
    white-space: nowrap;
    &.hide {
      display: none;
    }
  }

  .ant-table-thead .ant-table-column-sorters {
    padding: 0;
  }

  .ant-table-tbody > tr > td {
    font-weight: normal;
    color: rgb(0 0 0 / 0.75);
    background-color: #ffffff;
    font-size: 13px;
    padding: 1rem;
    &.hide {
      display: none;
    }
  }
  .ant-table {
    border-radius:8px;
  }

  .ant-table-content {
    border-radius: 8px;
  }
  .ant-table-tbody > tr:nth-child(odd) > td {
    /* background: #86559017; */
  }
`;

export const Xpagination = styled(Pagination)`
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0;
  .ant-select-selector {
    height: 33px !important;
    border-radius: 0px !important;
  }
`;