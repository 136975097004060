import ReactDOM from "react-dom/client";
import React, {useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate, Outlet } from "react-router-dom";
import { Breadcrumb, Layout, Menu, theme } from 'antd';
import './App.css';
import './css/app.min.css';
import './css/style.css';
import './css/components.css';
import './css/master.css';
import './css/responsive.css';
import Sidebar from "./component/Sidebar";
import PrivateRoute from "./component/PrivateRoute";
import Header from "./component/Header/Header";
// Pages
import Login from './pages/Login/Login'
import Dashboard from "./pages/Dashboard/Dashboard";
import Attendance from "./pages/Attendance/Attendance";
import Holiday from "./pages/Holiday/Holiday";
import Accounts from "./pages/Accounts/Accounts";
import NgoAdmin from "./pages/NgoAdmin/NgoAdmin";
import NgoGuide from "./pages/NgoGuide/NgoGuide";
import Fellow from "./pages/Fellow/Fellow";
import Ngos from "./pages/Ngos/Ngos";
import Leave from "./pages/Leave/Leave";

const { Content, Footer, Sider } = Layout;

function App() {
  
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />}></Route>
        <Route 
          path="/"
          element={
            <Layout
              style={{
                minHeight: '100vh',
              }}
            >
              <Sidebar/>
              {/* style={{ marginLeft: 200 }} */}
              <Layout className="site-layout" >
                <Content
                >
                  <Header></Header>
                  <Outlet />
                </Content>
              </Layout>
            </Layout>
          }
        >
          <Route exact path="/" element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
          >
          </Route>
          <Route exact path="attendance" element={
            <PrivateRoute>
              <Attendance />
            </PrivateRoute>
          }
          >
          </Route>
          <Route exact path="leave" element={
            <PrivateRoute>
              <Leave />
            </PrivateRoute>
          }
          >
          </Route>
          <Route exact path="holiday" element={
            <PrivateRoute>
              <Holiday />
            </PrivateRoute>
          }
          >
          </Route>
          <Route exact path="accounts" element={
            <PrivateRoute>
              <Accounts />
            </PrivateRoute>
          }
          >
          </Route>
          <Route exact path="ngos" element={
            <PrivateRoute>
              <Ngos />
            </PrivateRoute>
          }
          >
          </Route>
          <Route exact path="ngoadmin" element={
            <PrivateRoute>
              <NgoAdmin />
            </PrivateRoute>
          }
          >
          </Route>
          <Route exact path="ngoguide" element={
            <PrivateRoute>
              <NgoGuide />
            </PrivateRoute>
          }
          >
          </Route>
          <Route exact path="fellow" element={
            <PrivateRoute>
              <Fellow />
            </PrivateRoute>
          }
          >
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
