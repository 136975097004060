import { React, useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { api_call } from '../../utils';
import { Row, Col, Modal, notification, Button, Card, DatePicker, Tag, Space, Form, Select, Input, Tooltip, Popover } from 'antd';
import {Xtable, Xpagination} from '../../component/Xcomponent';
import moment from "moment";
import _ from "lodash";

const { confirm } = Modal;
const { RangePicker } = DatePicker;

const Leave = (props) => {

    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [leaveForm] = Form.useForm();
    const [rejectForm] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);
    const [rejectData, setRejectData] = useState({});
    const [leaveData, setLeaveData] = useState([]);
    const [role, setRole] = useState(localStorage.userRole);
    const [refresh, setRefresh] = useState(0);
    const [fetchFellowList, setFetchFellowlist] = useState(true);
    const [fetchNgoList, setFetchNgoList] = useState(true);
    const search = useLocation().search;
    const page = new URLSearchParams(search).get("page");
    const [pagination, setPagination] = useState({
        page: page ? page : 1,
        records: 10,
        total_records:0,
    });
    const [adminList, setAdminList] = useState([]);
    const [ngoList, setNgoList] = useState([]);
    const [fellowList, setFellowList] = useState([]); // used for listing fellows in search list of the filter for ngo guide
    const [totalRecords, setTotalRecords] = useState(0);
    const [selectedAdminId, setSelectedAdminId] = useState("");
    const [selectedNgoId, setSelectedNgoId] = useState("");
    const [selectedFellowId, setSelectedFellowId] = useState("");

    const fetchFellowLeaveList = (search) =>{

        api_call('get_fellow_leave_list',search)
        .then((res)=>{

            setLeaveData([]);
            
            let tableData = [];

            if(res.data.status)
            {
                res.data.data.map((item)=>{
                    
                    tableData.push({
                        date:item.date,
                        reason: item.reason,
                        status: item.status,
                        id:{
                            id:item.id
                        }
                    })
                })
            }
            
            setLeaveData(tableData);
        });
    }

    const fetchLeaveList = (search) =>{
        api_call('get_leave_list',search)
        .then((res)=>{
            setLeaveData([]);
            setTotalRecords()
            let tableData = [];
            if(res.data.status)
            {
                res.data.data.data.map((item)=>{
                    tableData.push({
                        name: item.userr.name,
                        admin: item.adminn.name,
                        ngo: item.ngoo.name,
                        // date:moment(item.start_date).format('Do MMM, YYYY'),
                        startDate:moment(item.start_date).format('Do MMM, YYYY'),
                        endDate:moment(item.end_date).format('Do MMM, YYYY'),
                        reason: item.leave_reason ? item.leave_reason : "Sick",
                        status: item.status,
                        remark: item.rejected_remark,
                        id:{
                            id:item.id,
                            status:item.status,
                        }
                    })
                })
            }
            setTotalRecords(res.data.data.pagination.total_records)
            setLeaveData(tableData);
        });
    }

    useEffect(()=>{

        if(role==3 || role==2 || role ==4)
        {
            fetchLeaveList({
                page:pagination.page,
                records:pagination.records,
                type:'normal',
            })
        } else if(role ==1)
        {
            fetchLeaveList({
                page:pagination.page,
                records:pagination.records,
                type:'normal',
            })
        }
        // else if(role==4){
        //     fetchFellowLeaveList({});
        // }
        
    },[pagination, refresh])

    useEffect(()=>{
        if(role==3 ||role==2 ||role==1 || role == 4)
        {
            navigate('/leave?page='+pagination.page);
        } 
    },[pagination])

    const columns = [
        {
            title:'Name',
            dataIndex:'name',
            key:'name'
        },
        {
            title: 'NGO Head',
            dataIndex: 'admin',
            key: 'admin'
        },
        {
            title: 'NGO Guide',
            dataIndex: 'ngo',
            key: 'ngo'
        },
        {
            title:'Start Date',
            dataIndex:'startDate',
            key:'startDate'
        },
        {
            title:'End Date',
            dataIndex:'endDate',
            key:'endDate'
        },
        {
            title:'Leave Reason',
            dataIndex:'reason',
            key:'reason',
            render: text => text || '-'
        },
        {
            title:'Status',
            dataIndex:'status',
            key:'status',
            render: (text,record)=>{

                if(text=="approved")
                {
                    return  (<Tag color={'green'} key={text}>{text.toUpperCase()}</Tag>)
                }
                else if(text=="pending")
                {
                    return  (<Tag color={'warning'} key={text}>{text.toUpperCase()}</Tag>)
                }
                else if (text === 'rejected') {
                    return (
                      <>
                        <Tag color="red">{text.toUpperCase()}</Tag>
                        {record.remark && (
                            <Popover
                                content={<div>{record.remark}</div>}
                                title="Rejected Remark"
                                trigger="click"
                           >
                            <Button ghost type="link" size="small">
                                View Remark
                            </Button>
                          </Popover>
                        )}
                      </>
                    );
                }
                else
                {
                    return "-";
                }
            }
        },

        {
            title:'Action',
            key:"id",
            dataIndex:"id",
            render: (data)=>{
                if(data.status=="pending")
                {
                    return <Space size="small">
                        <Button 
                            type="primary" 
                            size="small"
                            onClick={()=>updatedAttendanceStatus('approved',data.id)} 
                        >
                            Approve
                        </Button>
                        <Button 
                            type="primary" 
                            size="small" 
                            danger
                            onClick={() => showRejectModal('rejected',data.id)}
                        >
                            Reject
                        </Button>
                    </Space>
                }
            }
        }   
    ]

    const tableColumns = columns.filter(item=>{   
        if(item.key=='name'|| item.key=='id') {
            if(role==3 || role==2) {
                return item;
            } else if (role==1 || role ==2) {
                return item;
            } else if(role==1) {
                return item;
            }
        } else if (item.key == 'ngo') {
            return role == 1 || role == 2;
        } else if (item.key == 'admin') {
            return role == 1; 
        } 
        else
        {
            return item;
        }
    })

    const updatedAttendanceStatus = (status,id) => {
        let message = "";
        if(status=="approved") {
            message = "Do you want to approve this leave?";
            confirm({
                title:message,
                onOk() {
                    api_call('update_leave_status',{
                        atid:id,
                        status
                    })
                    .then((res)=>{
                        if(res.data.status)
                        {
                            setRefresh(refresh+1);
                            notification.success({
                                message: res.data.message,
                            });
                        }
                    })
                },
                onCancel() {
                },
            })
        }
    }

    const handleReject = (value) => {
        if(value.remark && rejectData.status==="rejected" && rejectData.id){
        api_call('update_leave_status',{
            atid:rejectData.id,
            status:rejectData.status,
            rejected_remark:value.remark,
        })
        .then((res)=>{
            if(res.data.status)
            {
                setRefresh(refresh+1);
                notification.success({
                    message: res.data.message,
                });
                handleCloseRejectModal();
            }
        })
    }
    }

    const onMonthChange = (value) => {
        if(value){
            fetchLeaveList({selectedMonth:value.format('YYYY-MM')});
        } else {
            setRefresh(refresh+1);
        }
    }

    const submitFilter = (value) => {
        // if(value.filter_month_year || value.fellow || value.ngo || "" )
        // {
            let data = {
                selectedMonth: value.filter_month_year ? value.filter_month_year.format("YYYY-MM") : "",
                search: value.fellow ? value.fellow : "",
                ngoGuide: value.ngo ? value.ngo : "",
                ngoAdmin: value.admin ? value.admin : "",
                page:1,
                records:10,
            }
            fetchLeaveList(data)
        // }
    }

    const handleClearFellow = ()=>{
       form.setFieldsValue({fellow:null})
       setSelectedFellowId('')
    }

    const handleClearAll = ()=>{
        form.setFieldsValue({fellow:null,ngo:null})
        setSelectedNgoId('')
        setSelectedFellowId('')
    }

    const showModal = (data = {}) => {
        setIsModalVisible(true);
        leaveForm.setFieldValue({})
    };

    const showRejectModal = (status,id) => {
        setIsRejectModalVisible(true);
        setRejectData({status,id})
    };

    const handleCloseRejectModal = () => {
        setIsRejectModalVisible(false)
        rejectForm.resetFields()
        rejectForm.setFieldsValue({});
        setRejectData({});
    }

    const handleCloseModal = () => {
        setIsModalVisible(false)
        leaveForm.resetFields()
        leaveForm.setFieldsValue({});
    }

    const submitLeave = (value) => {
        if(value){
            let data = {
                start_date: value.day ? value.day[0].format("YYYY-MM-DD") : "",
                end_date: value.day ? value.day[1].format("YYYY-MM-DD") : "",
                reason: value.reason ? value.reason : "",
            }
            api_call('mark_leave',data)
                .then((res)=>{
                    if(res.data.status)
                    {
                        setRefresh(refresh+1);
                        handleCloseModal();
                        notification.success({
                            message: res.data.message,
                        })
                    }
                })
                .catch((error) => {
                    console.log(error);
                    handleCloseModal()
                    notification.error({
                      message: error.response.data.message ? error.response.data.message  : "Something went wrong!",
                    });
                });
        }
    }

    // Function to disable previous dates
    const disabledDate = (current) => {
        // Get the start of the current day (today)
        const startOfCurrentDay = new Date();
        startOfCurrentDay.setHours(0, 0, 0, 0);
        // Disable dates before the start of the current day
        return current && current < startOfCurrentDay;
    };

    return (
       <>
            <Row gutter={{offset:1, span:23}} style={{margin:"10px"}}>
                {/* Reject Modal */}
                <Modal
                    title="Enter Remark"
                    open={isRejectModalVisible}
                    onCancel={handleCloseRejectModal}
                    footer={[ <>
                        <Button key="back" onClick={handleCloseRejectModal} >
                            Cancel
                        </Button>,
                        <Button button key="submit" danger type="primary" htmlType="submit" form="reject-form">
                            Reject
                        </Button>
                    </>
                    ]}
                >
                    <Form
                        id="reject-form"
                        form={rejectForm}
                        name="reject-form"
                        onFinish={handleReject}
                    > <br/>
                        <Form.Item
                            className="form-label label"
                            name="remark"
                            label="Remark"
                            labelCol={{span: 24}}
                            rules={[
                            {
                                required: true,
                                message: 'Please enter remark',
                            },
                            ]}
                        >
                            <Input.TextArea showCount maxLength={40} size="large" allowClear name="remark" placeholder="Enter Remark for Rejection"/>
                        </Form.Item>
                    </Form>
                </Modal>
                <Col span={24}>
                    <Card
                        title="Leave"
                        style={{
                            width:"100%",
                            minHeight:"100px",
                        }}
                        extra={ role == 4 && 
                        <>
                            <Button type="primary" onClick={showModal} >Apply for Leave</Button>
                            {/* Leave Modal */}
                            <Modal
                                title="Apply for leave"
                                open={isModalVisible}
                                onCancel={handleCloseModal}
                                footer={[
                                    <Button key="back" onClick={handleCloseModal}>
                                        Cancel
                                    </Button>,
                                    <Button button key="submit" type="primary" htmlType="submit" form="leave-form">
                                        Apply
                                    </Button>
                                ]}
                            >
                            <Form
                                id="leave-form"
                                form={leaveForm}
                                name="leave-form"
                                onFinish={submitLeave}
                            > <br/>
                                <Form.Item
                                    className="form-label label"
                                    name="reason"
                                    label="Reason"
                                    labelCol={{span: 24}}
                                    rules={[
                                    {
                                        required: true,
                                        message: 'Please enter a reason',
                                    },
                                    ]}
                                >
                                    <Input.TextArea showCount maxLength={100} size="large" allowClear name="reason" placeholder="Enter Reason for Leave"/>
                                </Form.Item>
                                <Form.Item
                                    className="form-label label"
                                    name="day"
                                    label="Date"
                                    labelCol={{span: 24}}
                                    rules={[
                                    {
                                        required: true,
                                        message: 'Please select a date',
                                    },
                                    ]}
                                >
                                    <RangePicker disabledDate={disabledDate} format={"DD-MMM-YYYY"} allowClear/>
                                </Form.Item>
                            </Form>
                            </Modal>
                        </>
                        }
                        // extra={ role == 4 && <>
                        // <Card.Grid 
                        //     className="noBoxShadow"
                        //     style={{
                        //         width:"100%", 
                        //         border:"none",
                        //         padding:"10px",
                        //     }}
                        // >   
                        // <Space direction='vertical'>
                        //     <Button type="primary" onClick={showConfirm}>Mark Attendance</Button>
                        //     <Button type="primary" onClick={showModal} style={{width:"138px"}}>Apply for Leave</Button>
                        // </Space>
                        // </Card.Grid>
                        // </>
                        // }
                    >
                        { role == 4 &&
                            <Row gutter={22} style={{marginBottom:"20px"}}>
                                <Col>
                                    <DatePicker size={"large"} picker="month" onChange={onMonthChange}></DatePicker>
                                </Col>
                            </Row>
                        }

                        { role == 3 &&
                            <Row gutter={24} style={{marginBottom:"20px"}}>
                                <Col span={24}>
                                    <Form 
                                        layout="inline"
                                        size="medium"
                                        name="filter_form"
                                        onFinish={submitFilter}
                                        style={{width:"100%"}}
                                    >
                                        <Row gutter={22}>
                                            <Col sm={12} md={12} lg={10} xl={10} style={{marginBottom:"10px"}}>
                                                <Form.Item
                                                    name="filter_month_year"
                                                    wrapperCol={24}
                                                >
                                                    <DatePicker placeholder="Select Month" picker="month" ></DatePicker>
                                                </Form.Item>
                                            </Col>
                                            <Col sm={12} md={12} lg={10} xl={10} style={{marginBottom:"10px"}}>
                                                <Form.Item
                                                    name="fellow"
                                                    wrapperCol={{span:24}}
                                                >
                                                    <Select
                                                        name="fellow"
                                                        placeholder="Select Fellow"
                                                        allowClear
                                                        onFocus={_.debounce((v)=>{
                                                            api_call('fetch_fellow_list',{
                                                                ngoId: selectedNgoId ? selectedNgoId :""
                                                            }).then((res)=>{
                                                                setFellowList([])
                                                                if(res.data.status)
                                                                {
                                                                    setFellowList(res.data.data)
                                                                }
                                                            })
                                                        },500)}
                                                        onSearch={_.debounce((v)=>{
                                                            api_call('fetch_fellow_list',{
                                                                search:v
                                                            }).then((res)=>{
                                                                setFellowList([])
                                                                if(res.data.status)
                                                                {
                                                                    setFellowList(res.data.data)
                                                                }
                                                            })
                                                        },500)}
                                                        showSearch
                                                        filterOption={false}
                                                    >
                                                        {fellowList.length>0 && 
                                                            fellowList.map(item=>(
                                                                <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
                                                            ))
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col sm={22} md={12} lg={4} xl={4} style={{marginBottom:"10px"}}>
                                                <Form.Item>
                                                    <Button htmlType="submit" type="primary" style={{width:"90px"}}>Filter</Button>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>
                            </Row>
                        }

                        { role == 1 &&
                            <Row gutter={24} style={{marginBottom:"20px"}}>
                                <Col span={24}>
                                    <Form
                                        form={form}
                                        // layout="inline"
                                        size="medium"
                                        name="filter_form"
                                        onFinish={submitFilter}
                                        style={{width:"100%"}}
                                    >
                                        <Row gutter={24}>
                                            <Col sm={12} md={6} lg={5} xl={3} >
                                                <Form.Item
                                                    name="filter_month_year"
                                                    wrapperCol={24}
                                                >
                                                    <DatePicker placeholder="Select Month" picker="month" ></DatePicker>
                                                </Form.Item>
                                            </Col>

                                            <Col sm={12} md={10} lg={5} xl={5} >
                                                <Form.Item
                                                    name="admin"
                                                    wrapperCol={24}
                                                >
                                                <Select
                                                    name="admin"
                                                    placeholder="Select NGO Head"
                                                    allowClear
                                                    onFocus={_.debounce((v)=>{
                                                        api_call('fetch_ngo_admin_list',{
                                                        }).then((res)=>{
                                                            setAdminList([])
                                                            if(res.data.status)
                                                            {
                                                                setAdminList(res.data.data)
                                                            }
                                                        })
                                                    },500)}
                                                    onSearch={_.debounce((v)=>{
                                                        api_call('fetch_ngo_admin_list',{
                                                            search:v
                                                        }).then((res)=>{
                                                            setAdminList([])
                                                            if(res.data.status)
                                                            {
                                                                setAdminList(res.data.data)
                                                            }
                                                        })
                                                    },500)}
                                                    showSearch
                                                    filterOption={false}
                                                    onChange={(value) => {setSelectedAdminId(value); handleClearAll()}}
                                                >
                                                    {adminList.length>0 && 
                                                        adminList.map(item=>(
                                                            <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
                                                        ))
                                                    }
                                                </Select>
                                                </Form.Item>
                                            </Col>

                                            <Col sm={12} md={10} lg={5} xl={5} >
                                                <Form.Item
                                                    name="ngo"
                                                    wrapperCol={24}
                                                >
                                                <Select
                                                    name="ngo"
                                                    placeholder="Select NGO Guide"
                                                    allowClear
                                                    onFocus={_.debounce((v)=>{
                                                        api_call('fetch_ngo_list',{
                                                            ngoAdminId : selectedAdminId ? selectedAdminId : ""
                                                        }).then((res)=>{
                                                            setNgoList([])
                                                            if(res.data.status)
                                                            {
                                                                setNgoList(res.data.data)
                                                            }
                                                        })
                                                    },500)}
                                                    onSearch={_.debounce((v)=>{
                                                        api_call('fetch_ngo_list',{
                                                            search:v,
                                                            ngoAdminId : selectedAdminId ? selectedAdminId : ""
                                                        }).then((res)=>{
                                                            setNgoList([])
                                                            if(res.data.status)
                                                            {
                                                                setNgoList(res.data.data)
                                                            }
                                                        })
                                                    },500)}
                                                    showSearch
                                                    filterOption={false}
                                                    onChange={(value) => {setSelectedNgoId(value); handleClearFellow()}}
                                                >
                                                    {ngoList.length>0 && 
                                                        ngoList.map(item=>(
                                                            <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
                                                        ))
                                                    }
                                                </Select>
                                                </Form.Item>
                                            </Col>

                                            <Col sm={12} md={10} lg={5} xl={5} >
                                                <Form.Item
                                                    name="fellow"
                                                    wrapperCol={24}
                                                >
                                                <Select
                                                    name="fellow"
                                                    placeholder="Select Fellow"
                                                    allowClear
                                                    onFocus={_.debounce((v)=>{
                                                        api_call('fetch_fellow_list',{
                                                            ngoId: selectedNgoId ? selectedNgoId :"",
                                                            ngoAdminId : selectedAdminId ? selectedAdminId : ""
                                                        }).then((res)=>{
                                                            setFellowList([])
                                                            if(res.data.status)
                                                            {
                                                                setFellowList(res.data.data)
                                                            }
                                                        })
                                                    },500)}
                                                    value={selectedFellowId}
                                                    onChange={(value) => setSelectedFellowId(value)}
                                                    onSearch={_.debounce((v)=>{
                                                        api_call('fetch_fellow_list',{
                                                            search:v,
                                                            ngoId: selectedNgoId ? selectedNgoId :"",
                                                            ngoAdminId : selectedAdminId ? selectedAdminId : ""
                                                        }).then((res)=>{
                                                            setFellowList([])
                                                            if(res.data.status)
                                                            {
                                                                setFellowList(res.data.data)
                                                            }
                                                        })
                                                    },500)}
                                                    showSearch
                                                    filterOption={false}
                                                >
                                                    {fellowList.length > 0 && 
                                                        fellowList.map(item=>(
                                                            <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
                                                        ))
                                                    }
                                                </Select>
                                                </Form.Item>
                                            </Col>

                                            <Col sm={22} md={5} lg={4} xl={5} >
                                                <Form.Item>
                                                    <Button htmlType="submit" type="primary" style={{width:"90px"}}>Filter</Button>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>
                            </Row>
                        }

                        { role == 2 &&
                            <Row gutter={24} style={{marginBottom:"20px"}}>
                                <Col span={24}>
                                    <Form
                                        form={form}
                                        layout="inline"
                                        size="medium"
                                        name="filter_form"
                                        onFinish={submitFilter}
                                        style={{width:"100%"}}
                                    >
                                        <Form.Item
                                            name="filter_month_year"
                                            wrapperCol={24}
                                            style={{marginBottom:"10px"}}
                                        >
                                            <DatePicker placeholder="Select Month" picker="month" ></DatePicker>
                                        </Form.Item>
                                        <Form.Item
                                            name="ngo"
                                            style={{marginBottom:"10px",width:"15%"}}
                                        >
                                        <Select
                                            name="ngo"
                                            placeholder="Select NGO Guide"
                                            allowClear
                                            onFocus={_.debounce((v)=>{
                                                api_call('fetch_ngo_list',{
                                                }).then((res)=>{
                                                    setNgoList([])
                                                    if(res.data.status)
                                                    {
                                                        setNgoList(res.data.data)
                                                    }
                                                })
                                            },500)}
                                            onSearch={_.debounce((v)=>{
                                                api_call('fetch_ngo_list',{
                                                    search:v
                                                }).then((res)=>{
                                                    setNgoList([])
                                                    if(res.data.status)
                                                    {
                                                        setNgoList(res.data.data)
                                                    }
                                                })
                                            },500)}
                                            showSearch
                                            filterOption={false}
                                            onChange={(value) => {setSelectedNgoId(value); handleClearFellow()}}
                                        >
                                            {ngoList.length>0 && 
                                                ngoList.map(item=>(
                                                    <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
                                                ))
                                            }
                                        </Select>
                                        </Form.Item>
                                        <Form.Item
                                            name="fellow"
                                            style={{marginBottom:"10px",width:"15%"}}
                                        >
                                        <Select
                                            name="fellow"
                                            placeholder="Select Fellow"
                                            allowClear
                                            onFocus={_.debounce((v)=>{
                                                api_call('fetch_fellow_list',{
                                                    ngoId: selectedNgoId ? selectedNgoId :""
                                                }).then((res)=>{
                                                    setFellowList([])
                                                    if(res.data.status)
                                                    {
                                                        setFellowList(res.data.data)
                                                    }
                                                })
                                            },500)}
                                            value={selectedFellowId}
                                            onChange={(value) => setSelectedFellowId(value)}
                                            onSearch={_.debounce((v)=>{
                                                api_call('fetch_fellow_list',{
                                                    search:v,
                                                    ngoId: selectedNgoId ? selectedNgoId :""
                                                }).then((res)=>{
                                                    setFellowList([])
                                                    if(res.data.status)
                                                    {
                                                        setFellowList(res.data.data)
                                                    }
                                                })
                                            },500)}
                                            showSearch
                                            filterOption={false}
                                        >
                                            {fellowList.length > 0 && 
                                                fellowList.map(item=>(
                                                    <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
                                                ))
                                            }
                                        </Select>
                                        </Form.Item>
                                        <Form.Item>
                                            <Button htmlType="submit" type="primary" style={{width:"90px"}}>Filter</Button>
                                        </Form.Item>
                                    </Form>
                                </Col>
                            </Row>
                        }
                        <Xtable
                            columns={tableColumns}
                            dataSource={leaveData}
                            pagination={false}
                            scroll={{x: 768}}
                            style={{
                                minHeight:"200px",
                            }}
                        />
                        {( role == 3 || role == 2 || role == 1 || role == 4) && (
                            <Xpagination
                                // className="appointmentPagin"
                                pageSizeOptions={["10", "25", "50", "100", "500"]}
                                showSizeChanger
                                total={totalRecords}
                                onChange={(page, pageSize) =>
                                    setPagination({ page: page, records: pageSize })
                                }
                                current={pagination.page}
                                defaultPageSize={pagination.records}
                                defaultCurrent={1}
                            />
                        )}
                    </Card>
                </Col>
            </Row> 
       </> 
    );
}

export default Leave