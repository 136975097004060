import {React, useState} from 'react';
import {Alert, Button, Form, Input} from 'antd';
import { redirect ,useNavigate} from "react-router-dom";
import { api_call } from '../../utils';
import ChangePassword from '../../component/ChangePassword';

const Login = () =>{

  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [userData, setUserData] = useState({
    name:"",
    role:"",
  });

  const loginUser = (values) => {

    let data = {
      phone: values.phone,
      password: values.password,
    }    

    api_call('login',data).then((res)=>{
      if(res.data.status)
      {
        localStorage.setItem("userToken",res.data.data.access_token);
        localStorage.setItem("cp",res.data.data.change_password);

        if(res.data.data.change_password)
        {
          setUserData({
            name:res.data.data.name,
            role:res.data.data.role,
          });
          
          setShowChangePassword(true);
        }
        else
        {
          localStorage.setItem("userName",res.data.data.name);
          localStorage.setItem("userRole",res.data.data.role);
          navigate('/');
        }
        
      }
      else
      {
        setErrorMessage(res.data.message);
      }  
    })
  } 

  const loginUserFailed = (error) => {
    console.log(error)
  } 

  return (
    <>      
      <div className="container-fluid bg-white" id="mobileCenterWrap">
        <div className="row justify-content-center align-items-center">
          <div className="col-12 col-sm-12 col-lg-6 ml-0 pl-0">
            <div className="loginBgAbs">&nbsp;</div>
          </div>
          { !showChangePassword ? 
            <div className="col-12 col-sm-12 col-lg-5 pl-md-5 mt-sm-4 mb-sm-4">
              <img className="img-fluid mb-5" src="images/logo.svg" alt=""/>
              <h1 className="loginHeading1 mb-2">Hey, hello <img width="50px" className="mt-n2" src="images/hand.gif"/></h1>
              <h3 className="loginHeading3 mb-5">Welcome please enter your details.</h3>
              {
                errorMessage!="" && 
                (
                  <Alert
                    style={{ marginBottom: 8 }}
                    message={errorMessage}
                    type="error"
                    showIcon
                  />
                )
              }
              <Form
                layout="vertical"
                size="large"
                name="login_form"
                onFinish={loginUser}
                onFinishFailed = {loginUserFailed}
              >
                <Form.Item
                  name="phone"
                  label="Phone"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your Username!',
                    },
                  ]}
                  style={{paddingBottom:"10px"}}
                >
                  <Input 
                    type="text"
                    placeholder="Username" 
                  />
                </Form.Item>

                <Form.Item
                  name="password"
                  label="Password"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your Password!',
                    },
                  ]}
                  style={{paddingBottom:"10px"}}
                >
                  <Input
                    type="password" 
                    placeholder="Password" 
                  />  
                </Form.Item>

                <Form.Item>
                  <Button htmlType="submit" className="btn btn-primary btn-lg btn-block" style={{height:"50px"}}>
                    Log in
                  </Button>
                </Form.Item>
              </Form>
            </div> 
            : 
            <ChangePassword userData={userData}></ChangePassword>

          }
          
          <div className="col-12 col-sm-1"></div>
        </div>
      </div>
    </>
  )
}

export default Login